import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss', 'confirm-modal.responsive.scss'],
})
export class ConfirmModalComponent implements OnInit {
	subtitle: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public modalData: { page: string; data: any },
		private matDialogRef: MatDialogRef<ConfirmModalComponent>,
		private translateService: TranslateService,
	) {}

	ngOnInit(): void {
		this.getSubtitle();
	}

	close(confirm = false) {
		this.matDialogRef.close(confirm);
	}

	getSubtitle(): void {
		const key = `modals.${this.modalData.page}.subtitle`;

		if (this.hasTranslation(key)) {
			this.translateService.get(key, this.modalData.data).subscribe((subtitle: string) => (this.subtitle = subtitle));
		}
	}

	hasTranslation(key: string): boolean {
		const translation = this.translateService.instant(key);
		return translation !== key && translation !== '';
	}
}
