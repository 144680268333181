import {
	ChangeDetectorRef,
	Component,
	ElementRef, EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import {
	TOP_STYLE_PELMET,
	COLOR_FRAME_PELMET,
	MESHES_IDS,
	FRAME_COLORS,
	TOP_STYLE_IDS,
	BOTTOM_BAR_IDS,
	SPLINE_MESH,
	INTERIOR_COLOR_FRAME_PELMET,
	BOTTOM_CHANNEL_HANDLE,
	INTERIOR_FRAME_TOP_STYLE,
	INTERIOR_END_CAPS, STORAGE_NAMES,
	VIEW_TYPES
} from '../../app.config';
import { SimpleChanges } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
import * as _ from 'lodash';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

@Component({
	selector: 'app-frame-change',
	templateUrl: './frame-change.component.html',
	styleUrls: ['./frame-change.component.scss', './frame-change.responsive.scss']
})
export class FrameChangeComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('frameColorItem') frameColorItem: ElementRef;
	@ViewChild('frameTopStyleItem') frameTopStyleItem: ElementRef;
	@ViewChild('frameBottomBarItem') frameBottomBarItem: ElementRef;
	
	@Input() blindId;
	@Output() frameData = new EventEmitter();
	frameColor;
	otherColor;
	customColor;
	materialType;
	getBlindSelect: Subscription;
	getMaterialColorChanged: Subscription;
	
	frameColorObject = {
		color: '',
		id: 0,
		name: '',
		is_custom: false
	};
	
	frameBottomBarObject = {
		id: 0,
		name: '',
		item: '',
		meshes: []
	};
	
	frameTopStyleObject = {
		id: 0,
		name: '',
		meshes: []
	};
	framesData = {
		frames: {
			top_style: {},
			frame_color: {},
			optionals: [],
		}
	};
	blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
	sessionConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config);
	sessionText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);
	isDesignType: boolean =
		this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
	random = Math.floor(10000 + Math.random() * 1000);
	
	defaultColor = '';
	defaultUncolored = '#818181';
	defaultMeshes = TOP_STYLE_PELMET;
	defaultBottomBar = MESHES_IDS[22];
	frameColors = FRAME_COLORS;
	customBottomBarId = 4;
	
	frames = [];
	topStyle = [];
	bottomBar = [];
	optionals = [];
	
	constructor(
		private engineService: EngineService,
		private shareDataService: ShareService,
		private sessionStorageService: SessionStorageService,
		private saveSessionBlind: SaveSessionBlindsService,
		private changeDetection: ChangeDetectorRef
	) {
	}
	
	static activeClassHandler(container, selector): void {
		for (const item of $(container).children()) {
			$(item).removeClass('active');
			$(selector).addClass('active');
		}
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		const getCurrentBLindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		
		if (changes.blindId.currentValue === getCurrentBLindId) {
			setTimeout(this.setDefaultValue.bind(this));
		}
	}
	
	ngOnInit(): void {
		this.getStorageFrame();
		
		this.frames = this.sessionConfig?.[this.blindType].frame.frame_color;
		
		if (this.blindType === 'outdoor') {
			this.topStyle = this.sessionConfig?.[this.blindType].frame.top_style;
			this.bottomBar = this.sessionConfig?.[this.blindType].frame.bottom_bar_protection;
		} else {
			this.optionals = this.sessionConfig?.[this.blindType].frame.optionals;
		}
		
		this.defaultColor = this.sessionConfig?.[this.blindType].frame.frame_color.filter(item => item.is_default === true)[0].color;
		
		this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(res => {
			this.setBlindSelectId(res);
		});
		this.getMaterialColorChanged = this.shareDataService.getMaterialColorChanged.subscribe(this.getMaterialType.bind(this));
	}
	
	ngOnDestroy(): void {
		this.getBlindSelect.unsubscribe();
		this.getMaterialColorChanged.unsubscribe();
	}
	
	getStorageFrame(id?: number): void {
		const getBlindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
		const frameStorage = this.sessionStorageService.getBlindItemById(id || this.blindId, STORAGE_NAMES.zip_blind_data);
		
		if (frameStorage) {
			for (const item of frameStorage) {
				if (item.setup && item.setup.frames) {
					this.framesData.frames = { ...item.setup.frames };
					this.frameColorObject = {
						is_custom: false,
						...item.setup.frames.frame_color
					};
					
					if (getBlindType === 'outdoor') {
						this.frameTopStyleObject = item.setup.frames.top_style;
						this.frameBottomBarObject = item.setup.frames.bottom_bar;
					} else if (getBlindType === 'interior') {
						const isChecked = !_.isEmpty(item.setup.frames.optionals);
						this.optionals = isChecked ? _.clone(item.setup.frames.optionals) : this.sessionConfig?.[getBlindType].frame.optionals;
						
						if (this.optionals?.length) {
							this.optionals[0].is_checked = isChecked;
						}
						
						this.setBottomChannel(isChecked, 'storage');
					}
				}
			}
		}
	}
	
	onSelectColor(data): void {
		this.getInteriorTopStyle();
		let framesData;
		this.frameColor = data.color;
		
		if (data.event) {
			this.otherColor = data.color;
		}
		
		if (_.isEmpty(this.framesData.frames)) {
			framesData = this.framesData = {
				frames: {
					...this.framesData.frames,
					frame_color: {
						id: data.id,
						name: data.name,
						color: data.color,
						color_name: data.color_name
					}
				}
			};
		} else {
			const frameColorChanged = {
				frame_color: {
					id: data.id,
					name: data.name,
					color: data.color,
					color_name: data.color_name
				}
			};
			framesData = {
				frames: { ...this.framesData.frames, ...frameColorChanged }
			};
			this.framesData.frames = { ...this.framesData.frames, ...frameColorChanged };
		}
		this.setModelColor(data.color);
		this.frameColorObject.is_custom = !_.isEmpty(data.is_custom);
		this.frameColorObject = { ...this.frameColorObject, ...data };
		this.saveBlindSetup(framesData);
	}
	
	onSelectTopStyle(data, event): void {
		if (event) {
			FrameChangeComponent.activeClassHandler(this.frameTopStyleItem.nativeElement, event.currentTarget);
		}
		
		const topStyleMeshes = TOP_STYLE_IDS.filter(item => item.id === data.id)[0].meshes;
		
		const topStyleData = {
			top_style: {
				id: data.id,
				name: data.name
			}
		};
		this.engineService.setTopStyle({
			meshes: topStyleMeshes,
			type: event.type,
			description: data.description
		});
		
		this.framesData.frames = { ...this.framesData.frames, ...topStyleData };
		this.saveBlindSetup();
	}
	
	onSelectBottomBar(data, event): void {
		if (event) {
			FrameChangeComponent.activeClassHandler(this.frameBottomBarItem.nativeElement, event.currentTarget);
		}
		
		const bottomBarMeshes = BOTTOM_BAR_IDS.filter(item => item.id === data.id)[0].meshes;
		
		const bottomBarData = {
			bottom_bar: {
				id: data.id,
				name: data.name
			}
		};
		this.engineService.setBottomBar({
			meshes: bottomBarMeshes,
			type: event.type,
			description: data.description
		});
		
		this.framesData.frames = { ...this.framesData.frames, ...bottomBarData };
		this.saveBlindSetup();
	}
	
	onSelectBottomChannel(data, state, event): void {
		this.getInteriorTopStyle();
		this.setBottomChannel(state, event.type, data.name);
		this.saveBlindSetup();
	}
	
	setBlindSelectId(data): void {
		this.getStorageFrame(data?.id);
		this.blindType = this.sessionStorageService.getBlindItemById(data?.id, STORAGE_NAMES.zip_blind_data)[0].type;
		this.frames = this.sessionConfig[this.blindType].frame.frame_color;
		
		if (data.status === 'new') {
			this.frameColorObject.color = this.defaultColor;
			
			if (this.blindType === 'outdoor') {
				this.frameBottomBarObject.item = this.defaultBottomBar;
			}
		}
		
		if (this.blindType === 'outdoor') {
			const topStyleData = TOP_STYLE_IDS.filter(item => item.id === this.frameTopStyleObject?.id);
			const bottomBarData = BOTTOM_BAR_IDS.filter(item => item.id === this.frameBottomBarObject?.id);
			
			this.topStyle = this.sessionConfig[this.blindType].frame.top_style;
			this.bottomBar = this.sessionConfig[this.blindType].frame.bottom_bar_protection;
			this.frameTopStyleObject.meshes = topStyleData[0]?.meshes || this.defaultMeshes;
			this.frameBottomBarObject.meshes = bottomBarData[0]?.meshes || [this.defaultBottomBar];
		}
		
		if (data.id === this.blindId) {
			const frameColor = this.frameColorObject.is_custom ? this.defaultUncolored : _.isEmpty(this.frameColorObject.color) ?
				this.defaultColor : this.frameColorObject.color;
			this.customColor = this.frameColorObject.is_custom ? this.frameColorObject.color : '';
			
			this.setModelColor(frameColor);
			
			if (this.blindType === 'outdoor') {
				const frameMeshes = _.isEmpty(this.frameTopStyleObject) && _.isEmpty(this.frameTopStyleObject.meshes) ?
					this.defaultMeshes : this.frameTopStyleObject.meshes;
				
				const frameBottomBar = _.isEmpty(this.frameBottomBarObject) && _.isEmpty(this.frameBottomBarObject.meshes) ?
					this.defaultMeshes : this.frameBottomBarObject.meshes;
				
				this.engineService.setTopStyle({
					meshes: frameMeshes,
					type: 'storage'
				});
				this.engineService.setBottomBar({
					meshes: frameBottomBar,
					type: 'storage'
				});
			}
			
			if (!_.isEmpty(this.frameColorObject.name) && this.frameColorObject.name === 'Other') {
				this.frameColor = frameColor;
				this.otherColor = frameColor;
			}
		}
		
		this.changeDetection.markForCheck();
	}
	
	setModelColor(color): void {
		const getBlindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
		const getBlindSetup = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0];
		let meshes;
		
		if (getBlindType === 'outdoor') {
			meshes = this.materialType === this.sessionConfig[getBlindType].material[0].type ? COLOR_FRAME_PELMET
				: [...COLOR_FRAME_PELMET, ...SPLINE_MESH];
		} else {
			meshes = INTERIOR_COLOR_FRAME_PELMET;
			
			if (getBlindSetup.setup?.size) {
				const width = getBlindSetup.setup.size['width'];
				const height = getBlindSetup.setup.size['height'];
				const topStyleId = INTERIOR_FRAME_TOP_STYLE.reduce((acc, x) => !acc && width > x.width && height > x.height ? x.id : acc, '');
				const topStyle = this.sessionConfig[getBlindType].frame.top_style.filter(el => el.id === topStyleId)[0];
				
				meshes = topStyle.id === INTERIOR_FRAME_TOP_STYLE[0].id ? [...meshes, ...INTERIOR_END_CAPS] : meshes;
			}
		}
		
		for (const meshId of meshes) {
			this.engineService.setColor(meshId, color, 1, 'frame', this.blindType);
		}
	}
	
	setBottomBarColor(color, meshes): void {
		for (const meshId of meshes) {
			this.engineService.setColor(meshId, color, 1, 'frame', this.blindType);
		}
	}
	
	setDefaultValue(): void {
		const getBlindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
		const frameStorage = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0];
		const frameConfig = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config)[getBlindType].frame;
		
		if (_.isEmpty(frameStorage && frameStorage.setup.frames?.frame_color)) {
			for (const color of frameConfig.frame_color) {
				if (color.is_default) {
					this.defaultColor = color.color;
					this.onSelectColor(color);
				}
			}
		}
		
		if (_.isEmpty(frameStorage && frameStorage.setup.frames?.top_style) && frameConfig.top_style) {
			for (const topStyle of frameConfig.top_style) {
				if (topStyle.is_default) {
					this.onSelectTopStyle(topStyle, false);
				}
			}
		}
		
		if (_.isEmpty(frameStorage && frameStorage.setup.frames?.bottom_bar) && frameConfig.bottom_bar_protection) {
			for (const bottomBar of frameConfig.bottom_bar_protection) {
				if (bottomBar.is_default) {
					this.defaultBottomBar = bottomBar.name;
					this.onSelectBottomBar(bottomBar, false);
				}
			}
		}
		
		if (!this.isDesignType && !_.isEmpty(frameStorage)) {
			this.setBlindSelectId({ id: this.blindId });
		}
	}
	
	onInputCustomColor(event, color): void {
		let framesData;
		framesData = this.framesData.frames;
		const lastSavedColor =
			framesData.frame_color.last_saved_color
				? framesData.frame_color.last_saved_color
				: !framesData.frame_color.is_custom
					? framesData.frame_color.color
					: this.defaultColor;
		color = color.trim();
		
		if (!_.isEmpty(this.framesData.frames) && !_.isEmpty(color)) {
			const colorProperty = {
				color,
				color_name: color,
				last_saved_color: lastSavedColor,
				id: 0,
				name: 'Custom',
				is_custom: true
			};
			
			this.customColor = color;
			this.frameColorObject = colorProperty;
			framesData.frame_color = this.frameColorObject;
			this.frameColor = this.defaultUncolored;
			FrameChangeComponent.activeClassHandler(this.frameColorItem.nativeElement, false);
			this.setModelColor(this.defaultUncolored);
		}
		
		if (_.isEmpty(color) || (!event.target.checked && event.type === 'change')) {
			const elem = $(this.frameColorItem.nativeElement).find(`[data-color="${ lastSavedColor }"]`)[0];
			this.frameColorObject.is_custom = false;
			
			if ($(elem).hasClass('other')) {
				const otherColor = {
					id: 99,
					name: 'Other',
					color: lastSavedColor
				};
				$(elem).addClass('active');
				this.onSelectColor(otherColor);
			} else {
				$(elem).click();
			}
		}
		
		if (this.frameColorObject.is_custom && (event.type === 'blur' || event.type === 'change' || event.type === 'focus')) {
			this.saveBlindSetup();
		}
	}
	
	getMaterialType(colorProperty): void {
		const getBlindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
		const getBlindSetup = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0];
		const isCurrentBlind = colorProperty.blind_id === this.blindId;
		const meshes = [...BOTTOM_BAR_IDS.filter(item => item.id === this.customBottomBarId)[0].meshes];
		const frameColorData = getBlindSetup?.setup?.frames?.frame_color;
		
		this.materialType = colorProperty.type;
		this.frameColor = frameColorData?.color || this.frameColor;
		this.otherColor = frameColorData?.name === 'Other' ? frameColorData?.color :
			FRAME_COLORS.map(item => item.color).includes(frameColorData?.last_saved_color) ?
				frameColorData?.last_saved_color : this.otherColor;
		
		// Sunscreen type
		if (colorProperty.type === this.sessionConfig[getBlindType].material[0].type && isCurrentBlind) {
			meshes.push(...SPLINE_MESH);
			this.setBottomBarColor(colorProperty.color, meshes);
		}
		
		// PVC type
		if (colorProperty.type === this.sessionConfig[getBlindType].material[1].type && this.frameColor && isCurrentBlind) {
			this.setBottomBarColor(this.defaultUncolored, meshes);
			this.setModelColor(this.frameColor);
		}
	}
	
	getInteriorTopStyle() {
		const top_style = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0].setup.frames?.top_style;
		
		this.framesData.frames = { ...this.framesData.frames, top_style };
	}
	
	onPopover(popover: NgbPopover, button: HTMLElement): void {
		$(button).toggleClass('active', popover.isOpen());
	}
	
	onSubmitCheckboxOptions(event): void {
		const currentOption = $(event.currentTarget);
		const isChecked = currentOption.prev().prop('checked');
		const optionID = currentOption[0].id;
		const { ...getCurrentData } = this.optionals.find(x => x.id === +optionID);
		
		if (!isChecked && !this.framesData.frames.optionals.length) {
			this.framesData.frames.optionals.push(getCurrentData);
		} else {
			this.framesData.frames.optionals.pop();
		}
		
		if (optionID === '1') {
			this.onSelectBottomChannel(getCurrentData, !isChecked, event);
		}
	}
	
	saveBlindSetup(framesData: any = this.framesData): void {
		this.frameData.emit(framesData);
		this.sessionStorageService.setBlindSetupDataById(this.blindId, framesData, STORAGE_NAMES.zip_blind_data);
		this.saveSessionBlind.PutStorageDataToServer();
	}
	
	setBottomChannel(state: boolean, type: string, description: string = ''): void {
		const blindData = this.sessionStorageService.getBlindItemById(this.blindId, STORAGE_NAMES.zip_blind_data)[0];
		const mounting = blindData?.setup?.mounting?.name?.toLowerCase();
		const isFaceFix = mounting === 'face fix';
		const bottomChannel = BOTTOM_CHANNEL_HANDLE.filter(name => !state || name.toLowerCase().includes(mounting));

		if (bottomChannel) {
			this.engineService.setBottomChannel({
				meshes: bottomChannel,
				state,
				type,
				description
			});
		}

		if (isFaceFix) {
			this.engineService.getInteriorFaceFixStopper(!state);
		}
	}

}
