import { Injectable } from '@angular/core';
import { CANVAS_BACKGROUND_COLOR, SELECTORS, STORAGE_NAMES } from '@root/app.config';
import { BlindData, ImageData, ScreenshotObserve } from '@root/app.interfaces';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';

import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class ScreenshotToBlindService {
	isScreenShotLoadedOnServer: boolean;

	constructor(
		private shareService: ShareService,
		private passDataService: PassDataService,
		private sessionStorageService: SessionStorageService,
		private serverDataService: ServerDataService
	) { }

	getCountryCode(): string {
		return this.sessionStorageService.getSession(STORAGE_NAMES.zip_country_code);
	}

	async getSceneScreenshot(id: number, type: string): Promise<void> {
		const renderCanvas = document.getElementById('renderCanvas') as HTMLCanvasElement;
		const width = renderCanvas.width;
		const height = renderCanvas.height;
		const newCanvas = document.createElement('canvas');

		newCanvas.width = width;
		newCanvas.height = height;

		const ctx = newCanvas.getContext('2d');

		const backgroundImageStyle = window.getComputedStyle(renderCanvas).backgroundImage;
		const ivCurrentBackground = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background);
		const isSampleProject = !!ivCurrentBackground?.type;

		return new Promise(resolve => {
			const getImage = (source: HTMLImageElement | HTMLVideoElement) => {
				ctx.fillStyle = CANVAS_BACKGROUND_COLOR;
				ctx.fillRect(0, 0, width, height);

				const bgWidth = source.width || source.clientWidth;
				const bgHeight = source.height || source.clientHeight;
				const aspectRatio = isSampleProject ?  bgWidth / bgHeight : width / height;
				const drawHeight = height;
				const drawWidth = drawHeight * aspectRatio;
				const offsetX = (width - drawWidth) / 2;
				const offsetY = 0;

				const isVideo = source.nodeName.toLocaleLowerCase() === 'video';
				if (!isVideo && !isSampleProject) {
					this.drawImageProp(ctx, source, width, height, 'contain');
				} else if (!isVideo) {
					ctx.drawImage(source, offsetX, offsetY, drawWidth, drawHeight);
				}

				ctx.drawImage(renderCanvas, 0, 0);

				const finalWidth = isSampleProject ? drawWidth : width;
				const finalHeight = isSampleProject ? drawHeight : height;
				const finalCanvas = document.createElement('canvas');
				finalCanvas.width = finalWidth;
				finalCanvas.height = finalHeight;

				const finalCtx = finalCanvas.getContext('2d');
				finalCtx.fillStyle = CANVAS_BACKGROUND_COLOR;
				finalCtx.fillRect(0, 0, finalWidth, finalHeight);

				finalCtx.drawImage(newCanvas, offsetX, offsetY, drawWidth, drawHeight, 0, 0, drawWidth, drawHeight);

				const canvasForExportImg = isVideo ? newCanvas : finalCanvas;
				const imgData = canvasForExportImg.toDataURL('image/jpeg', 0.3);

				this.setScreenshotToBlind({ img: imgData, blindId: id }, type, undefined, resolve);
			};

			const backgroundImageUrl = backgroundImageStyle.slice(5, -2);
			const backgroundVideo = document.querySelector(SELECTORS.live_background) as HTMLVideoElement;

			if (backgroundImageUrl) {
				const backgroundImage = new Image();
				backgroundImage.src = backgroundImageUrl;
				backgroundImage.onload = () => getImage(backgroundImage);
			} else if (backgroundVideo) {
				getImage(backgroundVideo);
			}
		});
	}

	drawImageProp(ctx, img, canvasWidth, canvasHeight, type) {
		const calculateFit = (contains, parentWidth, parentHeight, childWidth, childHeight, scale = 1, offsetX = 0.5, offsetY = 0.5) => {
			const childRatio = childWidth / childHeight;
			const parentRatio = parentWidth / parentHeight;
			let width = parentWidth * scale;
			let height = parentHeight * scale;

			if (contains ? (childRatio > parentRatio) : (childRatio < parentRatio)) {
				height = width / childRatio;
			} else {
				width = height * childRatio;
			}

			return {
				width,
				height,
				offsetX: (parentWidth - width) * offsetX,
				offsetY: (parentHeight - height) * offsetY
			};
		};

		if (type === 'cover') {
			const { offsetX, offsetY, width, height } = calculateFit(false, canvasWidth, canvasHeight, img.width, img.height);
			ctx.drawImage(img, offsetX, offsetY, width, height);
		} else if (type === 'contain') {
			const { offsetX, offsetY, width, height } = calculateFit(true, canvasWidth, canvasHeight, img.width, img.height);
			ctx.drawImage(img, offsetX, offsetY, width, height);
		}
	}

	imageDataHandler(data: ScreenshotObserve, type: string): ImageData {
		const tempSessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_temp_session);
		const sessionKey = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);

		const tempSessionName = type
			? `${ tempSessionKey }${ data.blindId ? '_' + data.blindId + '_' : '_' }${ type }`
			: `${ tempSessionKey }_${ data?.blindId }`;
		const sessionKeyName = type
			? `${ sessionKey }${ data.blindId ? '_' + data.blindId + '_' : '_' }${ type }`
			: `${ sessionKey }_${ data?.blindId }`;

		const name = !_.isEmpty(tempSessionKey) ? tempSessionName : sessionKeyName;

		return {
			country: this.getCountryCode(),
			image: data.img,
			name
		};
	}

	setScreenshotToBlind(data: ScreenshotObserve, type = '', blindsData?: BlindData[], resolve?: () => void): boolean {
		const imageData = this.imageDataHandler(data, type);
		this.isScreenShotLoadedOnServer = false;

		this.serverDataService.PostImageData(imageData).subscribe(res => {
			const blindData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
			const blindItem = blindData.filter((blind: BlindData) => blind.blind_id === data.blindId);
			const currentBlindsDataItem = blindsData?.filter(blind => blind.blind_id === data.blindId);
			this.isScreenShotLoadedOnServer = true;

			const blindObjectHandler = (blindObj: BlindData) => {
				if (type) {
					blindObj.iv_img = imageData.name + '.jpeg';
				} else {
					blindObj.blind_img = imageData.name + '.png';
				}
			};

			blindItem.map((blindObj: BlindData) => {
				blindObjectHandler(blindObj);
			});

			currentBlindsDataItem?.map(blindObj => {
				blindObjectHandler(blindObj);
			});

			this.sessionStorageService.setBlindData(blindData, STORAGE_NAMES.zip_blind_data);
			this.shareService.setScreenShotLoadedOnServer(this.isScreenShotLoadedOnServer);

			if (type === 'iv_scene') {
				this.sessionStorageService.setSession(res.name, STORAGE_NAMES.zip_iv_screenshot);
			}

			if (resolve) {
				resolve();
			}
		});

		return this.isScreenShotLoadedOnServer;
	}

}
