import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { SELECTORS, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
	providedIn: 'root',
})
export class OverviewLinkHandlerService {
	getScreenShotSaved: Subscription;

	constructor(
		private sessionStorageService: SessionStorageService,
		private saveSessionBlind: SaveSessionBlindsService,
		private screenShotService: ScreenshotService,
		private engineService: EngineService,
		private shareService: ShareService,
		private matDialog: MatDialog,
		private router: Router,
	) {}

	async link(callBack?: (value: void) => void) {
		this.engineService.setModelAndBoundingBoxSettings();
		await this.engineService.setIVSceneScreenShots();

		const lastOpenedBlindId =
			this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id) ||
			this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		const sizeDisclaimer = this.sessionStorageService.getSession(STORAGE_NAMES.zip_size_disclaimer);

		this.screenShotService.saveScreenShot(lastOpenedBlindId, true);
		this.getScreenShotSaved = this.shareService.getScreenShotSaved.subscribe(() => {
			const currentViewType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type);
			if (currentViewType === VIEW_TYPES.image_visualisation) {
				this.engineService.setGizmoZoomModelBehavior(false);
				this.engineService.saveModelAndBoundingBoxSettings();
				this.saveSessionBlind.PutStorageDataToServerPromise();
			}

			void this.router.navigate(['/overview']);
			document.addEventListener('DOMContentLoaded', () => {
				window.scrollTo(0, 0);
			});
			this.addActiveClass();
			this.getScreenShotSaved.unsubscribe();

			if (callBack) {
				callBack();
			}
		});

		this.shareService.setTaskbarPanel(false);
		this.engineService.isModelCreated = false;

		if (sizeDisclaimer) {
			this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_size_disclaimer);
		}

		const quizModal = this.matDialog.getDialogById('quiz-modal');
		quizModal?.close();
	}

	addActiveClass(): void {
		const navItems = document.querySelectorAll(SELECTORS.navigation_item);
		const lastNavItem = navItems[navItems.length - 1];

		lastNavItem.classList.add('active');
	}
}
