import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BlindData, BlindProperties, NetworkStatus, QuizCloseButton, IBlindSelect, Country } from '@root/app.interfaces';

@Injectable({
	providedIn: 'root',
})
export class ShareService {
	private country = new Subject<string>();
	private countryLink = new Subject<string>();
	private blindStatus = new Subject<boolean>();
	private blindUnselect = new Subject<boolean>();
	private blindSelect = new Subject<IBlindSelect>();
	private blindEmpty = new Subject<boolean>();
	private blindName = new Subject<string>();
	private blindSizeChanged = new Subject<any>();
	private dataSource = new Subject<BlindData[]>();
	private retailerInfo = new Subject<any>();
	private embeddedRetailer = new Subject<boolean>();
	private embeddedStatus = new Subject<boolean>();
	private linkSessionKey = new Subject<any>();

	private operationName = new Subject<string>();
	private materialType = new Subject<string>();
	private sessionBlindsData = new Subject<any>();
	private screenShotBLindTemp = new Subject<string>();
	private emptyBlindData = new Subject<any>();
	private cursorPointer = new Subject<any>();
	private cursorGrab = new Subject<any>();
	private retailerRequest = new Subject<any>();
	private userTime = new Subject<any>();
	private actionBackPage = new Subject<any>();
	private modelLoaded = new Subject<boolean>();
	private sceneCreated = new Subject<string>();
	private screenShotSaved = new Subject<any>();
	private screenShotAborted = new Subject<boolean>();
	private screenShotStatus = new Subject<boolean>();
	private screenShotLoadedOnServer = new Subject<boolean>();
	private loadingSpinner = new Subject<any>();
	private blindsUpdated = new Subject<any>();
	private materialColorChanged = new Subject<any>();
	private blindType = new Subject<BlindProperties>();
	private accordionType = new Subject<string>();
	private currentBlindByType = new Subject<string>();
	private accordionMobileBlindList = new Subject<boolean>();

	private viewType = new Subject<string>();

	private iVShutterControl = new Subject<boolean>();
	private iVResetStatus = new Subject<boolean>();
	private quizStatus = new Subject<QuizCloseButton>();

	private aRStatus = new Subject<string>();
	private checkExit = new Subject<any>();
	private networkStatus = new Subject<NetworkStatus>();
	private addBlind = new Subject<boolean>();
	private selectedModel = new Subject<string>();
	private taskbarPanel = new Subject<boolean>();
	private taskbarControls = new Subject<boolean>();
	private toolBarEvent = new Subject<boolean>();
	private gizmoControlAction = new Subject<boolean>();
	private resetCountrySubject = new Subject<Country>();

	getCountry = this.country.asObservable();
	getCountryLink = this.countryLink.asObservable();
	getStatus = this.blindStatus.asObservable();
	getBlindName = this.blindName.asObservable();
	getBlindSizeChanged = this.blindSizeChanged.asObservable();
	getBlindUnselect = this.blindUnselect.asObservable();
	getCurrentBlindByType = this.currentBlindByType.asObservable();
	getBlindSelect = this.blindSelect.asObservable();
	getBlindEmpty = this.blindEmpty.asObservable();
	currentData = this.dataSource.asObservable();
	getRetailerInfo = this.retailerInfo.asObservable();
	getEmbeddedRetailer = this.embeddedRetailer.asObservable();
	getEmptyBlindData = this.emptyBlindData.asObservable();
	getEmbeddedStatus = this.embeddedStatus.asObservable();
	getOperationName = this.operationName.asObservable();
	getMaterialType = this.materialType.asObservable();
	getLinkSessionKey = this.linkSessionKey.asObservable();
	getSessionBlindsData = this.sessionBlindsData.asObservable();
	getScreenShotBLindTemp = this.screenShotBLindTemp.asObservable();
	getCursorPointer = this.cursorPointer.asObservable();
	getCursorGrab = this.cursorGrab.asObservable();
	getRetailerRequest = this.retailerRequest.asObservable();
	getUserTime = this.userTime.asObservable();
	getActionBackPage = this.actionBackPage.asObservable();
	getModelLoaded = this.modelLoaded.asObservable();
	getSceneCreated = this.sceneCreated.asObservable();
	getScreenShotSaved = this.screenShotSaved.asObservable();
	getScreenShotAborted = this.screenShotAborted.asObservable();
	getScreenShotStatus = this.screenShotStatus.asObservable();
	getScreenShotLoadedOnServer = this.screenShotLoadedOnServer.asObservable();
	getLoadingSpinner = this.loadingSpinner.asObservable();
	getBlindsUpdated = this.blindsUpdated.asObservable();
	getMaterialColorChanged = this.materialColorChanged.asObservable();
	getBlindType = this.blindType.asObservable();
	getAccordionType = this.accordionType.asObservable();
	getAccordionMobileBlindList = this.accordionMobileBlindList.asObservable();

	getViewType = this.viewType.asObservable();
	getQuizStatus = this.quizStatus.asObservable();

	getIVShutterControl = this.iVShutterControl.asObservable();
	getIVResetStatus = this.iVResetStatus.asObservable();
	getARStatus = this.aRStatus.asObservable();
	getCheckExit = this.checkExit.asObservable();
	getNetworkStatus = this.networkStatus.asObservable();
	getAddBlind = this.addBlind.asObservable();
	getSelectedModel = this.selectedModel.asObservable();
	getTaskbarPanel = this.taskbarPanel.asObservable();
	getTaskbarControls = this.taskbarControls.asObservable();
	getToolBarEvent = this.toolBarEvent.asObservable();
	getGizmoControlAction = this.gizmoControlAction.asObservable();
	getResetCountry = this.resetCountrySubject.asObservable();

	setCountry(country: string): void {
		this.country.next(country);
	}

	setCountryLink(country: string): void {
		this.countryLink.next(country);
	}

	setStatus(status: boolean): void {
		this.blindStatus.next(status);
	}

	setBlindName(name: string): void {
		this.blindName.next(name);
	}

	setBlindSizeChanged(size: any): void {
		this.blindSizeChanged.next(size);
	}

	setBlindUnselect(select: boolean): void {
		this.blindUnselect.next(select);
	}

	setBlindSelect(select: IBlindSelect): void {
		this.blindSelect.next(select);
	}

	setBlindEmpty(select: boolean): void {
		this.blindEmpty.next(select);
	}

	changeData(data: any): void {
		this.dataSource.next(data);
	}

	setOperationName(select: string): void {
		this.operationName.next(select);
	}

	setMaterialType(select: string): void {
		this.materialType.next(select);
	}

	setRetailerInfo(data: any): void {
		this.retailerInfo.next(data);
	}

	setEmbeddedRetailer(data: any): void {
		this.embeddedRetailer.next(data);
	}

	setEmbeddedStatus(status: boolean): void {
		this.embeddedStatus.next(status);
	}

	setLinkSessionKey(data: any): void {
		this.linkSessionKey.next(data);
	}

	setSessionBlindsData(data: any): void {
		this.sessionBlindsData.next(data);
	}

	setScreenShotBLindTemp(data: string): void {
		this.screenShotBLindTemp.next(data);
	}

	setEmptyBlindData(data: any): void {
		this.emptyBlindData.next(data);
	}

	setCursorPointer(data: any): void {
		this.cursorPointer.next(data);
	}

	setCursorGrab(data: any): void {
		this.cursorGrab.next(data);
	}

	setRetailerRequest(event: any) {
		this.retailerRequest.next(event);
	}

	setUserTime(time: any) {
		this.userTime.next(time);
	}

	setActionBackPage(blindData: boolean) {
		this.actionBackPage.next(blindData);
	}

	setModelLoaded(status: any) {
		this.modelLoaded.next(status);
	}

	setSceneCreated(status: string) {
		this.sceneCreated.next(status);
	}

	setScreenShotSaved(status: any) {
		this.screenShotSaved.next(status);
	}

	setScreenShotAborted(status: any) {
		this.screenShotAborted.next(status);
	}

	setScreenShotStatus(status: any) {
		this.screenShotStatus.next(status);
	}

	setScreenShotLoadedOnServer(status: boolean) {
		this.screenShotLoadedOnServer.next(status);
	}

	setLoadingSpinner(status: any) {
		this.loadingSpinner.next(status);
	}

	setBlindsUpdated(status: any) {
		this.blindsUpdated.next(status);
	}

	setMaterialColorChanged(color: any) {
		this.materialColorChanged.next(color);
	}

	setBlindType(data) {
		this.blindType.next(data);
	}

	setAccordionType(type: string) {
		this.accordionType.next(type);
	}

	setViewType(type: string) {
		this.viewType.next(type);
	}

	setIVShutterControl(status: boolean) {
		this.iVShutterControl.next(status);
	}

	setIVResetStatus(status: boolean) {
		this.iVResetStatus.next(status);
	}

	setARStatus(status: string) {
		this.aRStatus.next(status);
	}

	setQuizStatus(data: QuizCloseButton) {
		this.quizStatus.next(data);
	}

	setCurrentBlindByType(type: string) {
		this.currentBlindByType.next(type);
	}

	setCheckExit(type: any) {
		this.checkExit.next(type);
	}

	setNetworkStatus(status: NetworkStatus) {
		this.networkStatus.next(status);
	}

	setAddBlind(saveScreenShot: boolean) {
		this.addBlind.next(saveScreenShot);
	}

	setSelectedModel(id: string) {
		this.selectedModel.next(id);
	}

	setTaskbarPanel(status: boolean) {
		this.taskbarPanel.next(status);
	}

	setTaskbarControls(status: boolean) {
		this.taskbarControls.next(status);
	}

	setToolBarEvent(status: boolean) {
		this.toolBarEvent.next(status);
	}

	setGizmoControlAction(status: boolean) {
		this.gizmoControlAction.next(status);
	}

	setAccordionMobileBlindList(status: boolean) {
		this.accordionMobileBlindList.next(status);
	}

	resetCountry(country: Country) {
		this.resetCountrySubject.next(country);
	}
}
