<mat-form-field class="mc-country-selector" *ngIf="selectedCountry">
	<mat-select
		#matSelect
		class="mc-country-selector__select"
		[class]="{ opened: isOpen }"
		[value]="selectedCountry"
		(openedChange)="onToggle($event)"
		(valueChange)="openConfirmationModal($event)"
		(click)="onClick()"
		hideSingleSelectionIndicator="true"
		panelClass="mc-country-selector-align"
		panelWidth="null"
	>
		<mat-select-trigger class="mc-country-selector__select-trigger">
			<img
				class="mc-country-selector__select-icon"
				[src]="'/assets/icons/flags/' + selectedCountry.code + '.svg'"
				[alt]="selectedCountry.name"
			/>
			<span class="mc-country-selector__select-title-text">{{ selectedCountry.code.toUpperCase() }}</span>
		</mat-select-trigger>
		<mat-option class="mc-country-selector__select-option" *ngFor="let country of countries" [value]="country">
			<img class="mc-country-selector__select-icon" [src]="'/assets/icons/flags/' + country.code + '.svg'" [alt]="country.name" />
			<span class="mc-country-selector__select-text">{{ country.name }}</span>
			<div class="mc-country-selector__select-checkbox" *ngIf="selectedCountry.code === country.code"></div>
		</mat-option>
	</mat-select>
</mat-form-field>
