<div #wrapper class="mc-header-wrapper" [ngClass]="{ 'no-overview': !isBlindTypeSelected }">
	<a href="" class="mc-logo" (click)="openModal($event)">Logo</a>
	<ul #navMenu class="mc-nav-menu" *ngIf="isBlindTypeSelected">
		<li routerLinkActive="active" routerLink="/home" id="header-design-button">
			{{ 'header.design' | translate }}
		</li>
		<li (click)="overviewLinkHandler(overviewStatus)" id="header-overview-button">
			{{ 'header.overview' | translate }}
		</li>
	</ul>

	<app-country-selector class="mc-country-selector" *ngIf="!isEmbedded"></app-country-selector>

	<app-taskbar *ngIf="isBlindTypeSelected" class="mc-header__taskbar"></app-taskbar>

	<a href [ngClass]="isEmbedded ? 'mc-header__refresh' : 'mc-header__exit'" (click)="openModal($event)">
		{{ (isEmbedded ? 'header.refresh' : 'header.exit') | translate }}
	</a>
</div>
