import { MatDialogConfig } from '@angular/material/dialog';
import { STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';

const isQuizNeedOffset = (): boolean => {
	const viewType = JSON.parse(sessionStorage.getItem(STORAGE_NAMES.zip_view_type));
	const isDesignType = viewType === VIEW_TYPES.design;
	const ivBackgroundImage = JSON.parse(sessionStorage.getItem(STORAGE_NAMES.zip_image_visualisation_background));

	return isDesignType || ivBackgroundImage?.type;
};

type DialogConfig = {
	[propName: string]: MatDialogConfig
};

export function getModalsConfig(): DialogConfig {
	const hasOffset = isQuizNeedOffset();
	
	return {
		ArLoadingModalComponent: {
			width: '100vw',
			maxWidth: '100vw',
			height: '100vh',
			disableClose: true,
			closeOnNavigation: false
		},
		QuizModalComponent: {
			id: 'quiz-modal',
			panelClass: ['mc-modal__quiz-pane', hasOffset ? 'mc-modal__quiz-offset' : ''],
			hasBackdrop: !hasOffset,
			backdropClass: !hasOffset ? 'mc-modal__quiz-backdrop' : null,
			disableClose: !hasOffset,
			maxWidth: '100vw',
			autoFocus: false
		},
		SelectBackgroundModalComponent: {
			width: 'max-content',
			maxWidth: '940px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		},
		RotateDeviceModalComponent: {
			id: 'rotate-device-modal',
			width: 'max-content',
			maxWidth: '590px',
			height: 'auto',
			disableClose: true,
			panelClass: 'mc-modal__hide-behind',
			backdropClass: 'mc-modal__hide-behind-backdrop',
		},
		SaveWorkEmailModalComponent: {
			width: '100%',
			maxWidth: '690px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		},
		DisabledLinkModalComponent: {
			width: '100vw',
			maxWidth: '100vw',
			height: '100vh',
			panelClass: ['mc-modal__internal', 'mc-modal__full-screen']
		},
		ArNoBlindsModalComponent: {
			width: '100%',
			maxWidth: '600px',
			panelClass: 'mc-modal__internal'
		},
		AnotherDeviceModalComponent: {
			id: 'another-device-modal',
			width: '100%',
			maxWidth: '700px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		},
		LinkUnavailableModalComponent: {
			width: '100%',
			maxWidth: '700px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal',
			disableClose: true,
			closeOnNavigation: false
		}
	};
}
