import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

type DialogConfig = {
	[propName: string]: MatDialogConfig;
};

export function getModalsConfig(): DialogConfig {
	return {
		ArLoadingModalComponent: {
			width: '100vw',
			maxWidth: '100vw',
			height: '100vh',
			disableClose: true,
			closeOnNavigation: false,
		},
		QuizModalComponent: {
			id: 'quiz-modal',
			panelClass: ['mc-modal__quiz-pane', 'mc-modal__quiz-offset'],
			hasBackdrop: false,
			backdropClass: null,
			disableClose: false,
			maxWidth: '100vw',
			autoFocus: false,
		},
		SelectBackgroundModalComponent: {
			id: 'select-background',
			width: 'max-content',
			maxWidth: '940px',
			height: 'auto',
			minHeight: '150px',
			panelClass: 'mc-modal__internal',
		},
		RotateDeviceModalComponent: {
			id: 'rotate-device-modal',
			width: 'max-content',
			maxWidth: '590px',
			height: 'auto',
			disableClose: true,
			panelClass: 'mc-modal__hide-behind',
			backdropClass: 'mc-modal__hide-behind-backdrop',
		},
		SaveWorkEmailModalComponent: {
			width: '100%',
			maxWidth: '690px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal',
		},
		DisabledLinkModalComponent: {
			width: '100vw',
			maxWidth: '100vw',
			height: '100vh',
			panelClass: ['mc-modal__internal', 'mc-modal__full-screen'],
		},
		ArNoBlindsModalComponent: {
			width: '100%',
			maxWidth: '600px',
			panelClass: 'mc-modal__internal',
		},
		AnotherDeviceModalComponent: {
			id: 'another-device-modal',
			width: '100%',
			maxWidth: '700px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal',
		},
		LinkUnavailableModalComponent: {
			width: '100%',
			maxWidth: '700px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal',
			disableClose: true,
			closeOnNavigation: false,
		},
		ConfirmModalComponent: {
			width: '100%',
			maxWidth: '720px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal',
		},
	};
}
